import { InformationBlock } from '../shared/ui/informationBlock/informationBlock.tsx'
import { Navigate, useParams } from 'react-router-dom'
import { useAppModule } from '../features/appContext.ts'
import { planInfos } from '../features/onboarding/subscriptionView.tsx'
import { urls } from '../shared/urls.ts'
import { SubscriptionPlan } from '../shared/api/chatApi.ts'
import { FormattedMessage } from 'react-intl'
import { convertToId } from '../shared/lib/stringUtils.ts'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type RouteParams = {
  status: 'success' | 'error'
  plan?: '1m' | '3m' | '12m' | undefined
}
export const PaymentStatusPage = () => {
  const authStore = useAppModule().authStore
  const params = useParams<RouteParams>()
  const title =
    params.status === 'success' ? 'payment.success' : 'payment.error'
  const imageUrl =
    params.status === 'success'
      ? '/images/status-success.svg'
      : '/images/status-error.png'
  const buttonText = params.status == 'success' ? 'Continue' : 'payment.return'
  const returnUrl = params.status == 'success' ? urls.index : urls.subscriptions

  const plan = params.plan?.toUpperCase() as SubscriptionPlan | undefined

  if (params.status == 'success') {
    if (!plan) {
      return <Navigate to={urls.index} />
    }
    authStore.trackMetrika('paing_finished')
    authStore.trackMetrika(`trial_started_${plan}`)
    authStore.trackMetrika(`demo2_successful_payment`)
  }
  const content = plan ? (
    <div className="mb-16 flex flex-col items-center font-medium leading-8 text-gray6">
      <div className="text-center text-18 ">
        <FormattedMessage id="payment.you subscribed" />{' '}
        <FormattedMessage
          id="months"
          values={{ count: planInfos[plan].month }}
        />
        .{' '}
        <FormattedMessage
          id="payment.password"
          values={{
            email: (
              <span className="font-extrabold">
                {authStore.state.user?.login}
              </span>
            ),
          }}
        />
        <div className="mt-24">
          <FormattedMessage
            id="payment.contacts"
            values={{
              contacts: <FormattedMessage id="contacts.title" />,
              telegram: (
                <a className="underline" href="https://t.me/edman_ai">
                  @edman_ai
                </a>
              ),
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  )

  const buttonId =
    params.status == 'error'
      ? undefined
      : convertToId('payment-success-' + plan)

  return (
    <div className="flex w-full flex-col">
      <InformationBlock
        imageUrl={imageUrl}
        title={<FormattedMessage id={title} />}
        buttonText={<FormattedMessage id={buttonText} />}
        buttonId={buttonId}
        returnUrl={returnUrl}
      >
        {content}
      </InformationBlock>
    </div>
  )
}

import React, { useLayoutEffect } from 'react'
import { useAppModule } from '../features/appContext.ts'
import { isWebDomain, urls } from '../shared/urls.ts'
import { Navigate, useNavigate } from 'react-router-dom'
import { useOnboardingStore } from '../features/onboarding/OnboardingView.tsx'
import { useSnapshot } from 'valtio/react'

export function AuthAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore

  if (!authStore.isLogin()) {
    const url = isWebDomain ? urls.chooseyourlevel : urls.login
    return <Navigate to={url} />
  } else if (!authStore.isConfirm()) {
    return <Navigate to={urls.confirm} />
  } else if (authStore.state.isAnon) {
    return <Navigate to={urls.onboarding()} />
  }
  return props.children
}

export function DemoAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore
  const onboardingStore = useOnboardingStore()
  const onboardingState = useSnapshot(onboardingStore.state)
  if (!authStore.isLogin()) {
    return <Navigate to={urls.onboarding()} />
  }
  if (
    authStore.isLogin() &&
    authStore.isConfirm() &&
    !authStore.state.isAnon &&
    !onboardingState.goToDemo
  ) {
    return <Navigate to={urls.learningProfile()} />
  }
  onboardingStore.resetGoToDemo()
  return props.children
}

export function LoginAccess(props: { children: React.ReactNode }) {
  const { authStore, appSessionStorage } = useAppModule()
  const isOnboardingCompleted = appSessionStorage.get('onboarding')?.completed

  if (authStore.isLogin() && !authStore.state.isAnon && isOnboardingCompleted) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

export function ConfirmAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore

  if (authStore.isLogin() && !authStore.state.isAnon && authStore.isConfirm()) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

export function SubscriptionsAccess(props: { children: React.ReactNode }) {
  const authStore = useAppModule().authStore
  const subscriptionService = useAppModule().subscriptionService()
  const onboardingStore = useOnboardingStore()
  const onboardingState = useSnapshot(onboardingStore.state)
  const navigate = useNavigate()

  useLayoutEffect(() => {
    const checkSubscriptions = async () => {
      try {
        const response = await subscriptionService.getActiveSubscription()

        if (response) {
          navigate(urls.learningProfile(), { replace: true })
        }
      } catch {}
    }

    void checkSubscriptions()
  }, [subscriptionService, navigate])

  if (!authStore.isLogin() || !onboardingState.completed) {
    return <Navigate to={urls.onboarding()} />
  }

  if (
    authStore.isLogin() &&
    !authStore.state.isAnon &&
    !authStore.isConfirm()
  ) {
    return <Navigate to={urls.confirm} />
  }

  if (
    authStore.isLogin() &&
    !authStore.state.isAnon &&
    authStore.isConfirm() &&
    onboardingState.hasSubscription
  ) {
    return <Navigate to={urls.learningProfile()} />
  }

  return props.children
}

import { FillTheGapsStore } from './fillTheGapsStore.ts'
import { useLazyRef } from '../../../shared/lib/hooks.ts'
import { useSnapshot } from 'valtio/react'
import {
  ExerciseButton,
  ExerciseContainer,
  ExerciseTitle,
} from '../exerciseComponents.tsx'
import { classed } from '@tw-classed/react'
import React from 'react'
import { TaskSlider } from '../taskSlider/TaskSlider.tsx'
import { useAppModule } from '../../appContext.ts'

type FillWordText = readonly (string | { word: string })[]
export interface FillWordTextData {
  text: FillWordText
  options: readonly string[]
  incorrect: string
  title: string
}
interface FillWordsProps {
  texts: readonly FillWordTextData[]
  completed: boolean
  onComplete: (result: string, correctAnswersCount: number) => void
}
export const FillTheGaps = (props: FillWordsProps) => {
  const answerStore = useAppModule().answerStatusStore
  const store = useLazyRef(
    () => new FillTheGapsStore(props.texts, answerStore, props.onComplete),
  ).current
  const state = useSnapshot(store.state)
  const currentTask = state.texts[state.currentIndex]

  return (
    <ExerciseContainer>
      <TaskSlider
        id={state.currentIndex}
        isLast={state.currentIndex == state.texts.length - 1}
      >
        <ExerciseTitle title={currentTask.title} className="mb-52" />
        <Sentence text={currentTask.text} store={store} />

        <div className="mt-52 grid grid-cols-3 gap-8">
          {state.words.map((word, i) => {
            const isAnswer = state.currentAnswer?.index == i
            return (
              <ExerciseButton
                key={i}
                onClick={() => {
                  store.select(i)
                }}
                isCorrect={isAnswer ? 'selected' : 'none'}
              >
                {word}
              </ExerciseButton>
            )
          })}
        </div>
      </TaskSlider>
    </ExerciseContainer>
  )
}

function Sentence(props: { text: FillWordText; store: FillTheGapsStore }) {
  const state = useSnapshot(props.store.state)
  const maxWordLength = Math.max(...state.words.map((x) => x.length))
  return (
    <div className="text-center text-20 font-semibold">
      {props.text.map((part, i) => {
        if (typeof part === 'string') {
          return <span key={i}>{part}</span>
        } else {
          const content = state.currentAnswer
            ? state.words[state.currentAnswer.index]
            : '&ensp;'.repeat(maxWordLength)
          return (
            <Word
              answer={!!state.currentAnswer}
              isCorrect={state.currentAnswer?.isCorrect ?? 'none'}
              key={i}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Word>
          )
        }
      })}
    </div>
  )
}

const Word = classed.span('mx-8 inline-block py-8', {
  variants: {
    isCorrect: {
      true: 'text-green-dark',
      false: 'text-alert',
      none: '',
    },
    answer: {
      true: 'rounded-8 bg-white px-12',
      false: 'underline',
    },
  },
})

import React from 'react'
import { useProfileStore } from './ProfileStore.ts'
import { useSnapshot } from 'valtio'
import { useNavigate } from 'react-router-dom'
import { urls } from '../../shared/urls.ts'
import { Button } from '../../shared/ui/button/button.tsx'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'

export const ProfileSubscription = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const navigate = useNavigate()
  const subscription = state.subscription
  return (
    <div className="flex flex-col gap-16">
      <ProfileTitle title={'Manage Subscription'} />
      <ProfileMenuBlock>
        <ProfileButton
          withArrow
          onClick={() => {
            navigate(urls.subscriptions)
          }}
          title="Change subscription"
          disabled={true}
          subtitle={state.subscription?.recurrent ? 'Every month payment' : ''}
        />
      </ProfileMenuBlock>
      {subscription?.recurrent && (
        <ProfileMenuBlock>
          <ProfileButton
            alertTitle
            onClick={() => {
              store.setShowCancelSubscription(true)
            }}
            title="Cancel subscription"
          />
        </ProfileMenuBlock>
      )}

      <CancelSubscriptionDrawer />
    </div>
  )
}

function CancelSubscriptionDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  return (
    <ProfileDrawer
      title="Cancel subscription?"
      open={state.showCancelSubscription}
    >
      <div className="flex flex-col gap-16">
        <div>Are you sure you want to cancel your subscription?</div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            void store.unsubscribe()
          }}
        >
          Yes
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowCancelSubscription(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </ProfileDrawer>
  )
}

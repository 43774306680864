import React from 'react'

import {
  ProfileButton,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'

const contacts = [
  {
    title: 'Telegram',
    img: '/images/contacts/telegram.svg',
    url: 'https://t.me/edman_ai',
    urlTitle: '@edman_ai',
  },
  {
    title: 'Whatsapp',
    img: '/images/contacts/whatsapp.svg',
    url: 'https://wa.me/message/4DA7WT5FGXIRE1',
    urlTitle: 'Edman Ai',
  },
  {
    title: 'E-mail',
    img: '/images/contacts/email.svg',
    url: 'mailto:info@edman.ai',
    urlTitle: 'info@edman.ai',
  },
]

export const ProfileSupport = () => {
  return (
    <div>
      <ProfileTitle title={'Support'} />
      <div className="flex flex-col gap-16">
        {contacts.map((x, i) => {
          return (
            <ProfileMenuBlock key={i}>
              <ProfileButton
                withArrow
                imageUrl={x.img}
                onClick={() => {
                  window.open(x.url, '_blank')
                }}
                title={x.title}
                subtitle={x.urlTitle}
              />
            </ProfileMenuBlock>
          )
        })}
      </div>
    </div>
  )
}

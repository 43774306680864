import { FormattedMessage } from 'react-intl'
import { useIsMobile } from '../shared/lib/hooks.ts'

export function PracticePage() {
  const isMobile = useIsMobile()
  return (
    <div className="h-screen w-full px-16 pt-16">
      <div className=" relative z-20 flex h-full flex-col items-center justify-center gap-12 overflow-hidden rounded-18 bg-blue-60 p-16">
        {isMobile && (
          <img
            src="/images/practicePageExample.png"
            className="absolute size-full opacity-50"
          />
        )}
        <p className="z-10 text-center text-18 font-bold text-black">
          <FormattedMessage id="practice.soonTitle" />
        </p>
        <p className="z-10 text-center text-16 font-normal text-black">
          <FormattedMessage id="practice.soonText" />
        </p>
      </div>
    </div>
  )
}

import React from 'react'

import {
  ProfileButton,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'
import { recurrentTermsUrl, termsUrl } from '../auth/login.tsx'

const links = [
  {
    title: 'Terms of Service',
    url: recurrentTermsUrl,
  },
  {
    title: 'Privacy Policy',
    url: termsUrl,
  },
] as const

export const ProfileAbout = () => {
  return (
    <div>
      <ProfileTitle title={'About'} />
      <div className="flex flex-col gap-16">
        {links.map((x, i) => {
          return (
            <ProfileMenuBlock key={i}>
              <ProfileButton
                withArrow
                onClick={() => {
                  window.open(x.url, '_blank')
                }}
                title={x.title}
              />
            </ProfileMenuBlock>
          )
        })}
      </div>
    </div>
  )
}

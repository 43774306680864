import React, { AnimationEvent, useRef } from 'react'
import { cn } from '../../lib/utils.ts'
import { isWord, splitToWordsAndSymbols } from '../../lib/stringUtils.ts'
import { classed } from '@tw-classed/react'

interface ClickableWordsProps {
  children: string
  className?: string
  selectedWords: readonly { word: string; dark: boolean }[]
  onClick?: (word: string, add: boolean) => void
  delay?: number
  animateSpeed?: 'slow' | 'fast'
  disabled: boolean
  onLastDisplayEnd?: () => void
}

const WordContainer = classed.span('', {
  variants: {
    animate: {
      true: 'inline-block size-0 animate-appShow opacity-0 fill-mode-forwards',
      false: 'fill-mode-forwards',
    },
    state: {
      dark: 'rounded-6 bg-blue-light text-black',
      light: 'rounded-6 bg-orange-dark text-default',
      selected: 'rounded-6 bg-purple-light',
    },
  },
})

// eslint-disable-next-line sonarjs/cognitive-complexity
function Word(props: {
  currentIndex: number | null
  setCurrentIndex: (index: number | null) => void
  word: string
  index: number
  selectedWords: readonly { word: string; dark: boolean }[]
  onClick?: (word: string, add: boolean) => void
  animateSpeed?: 'slow' | 'fast'
  delay?: number
  onLastDisplayEnd?: () => void
}) {
  const animate = !!props.animateSpeed
  const style = props.animateSpeed
    ? {
        animationDelay: `${
          (props.delay ?? 0) +
          props.index * (props.animateSpeed == 'fast' ? 10 : 30)
        }ms`,
      }
    : {}

  const handleAnimationEnd = (event: AnimationEvent<HTMLSpanElement>) => {
    event.currentTarget.classList.remove('inline-block')
    props.onLastDisplayEnd?.()
  }

  if (!isWord(props.word)) {
    return (
      <WordContainer
        animate={animate}
        style={style}
        key={props.index}
        onAnimationEnd={props.animateSpeed ? handleAnimationEnd : undefined}
      >
        {props.word}
      </WordContainer>
    )
  }

  const selectedWord = props.selectedWords.find((x) => x.word == props.word)
  const isCurrent = props.index == props.currentIndex
  const state = isCurrent
    ? 'selected'
    : selectedWord == undefined
    ? undefined
    : selectedWord.dark
    ? 'dark'
    : 'light'

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.word, !selectedWord)
    }
  }

  return (
    <WordContainer
      animate={animate}
      style={style}
      state={state}
      onAnimationEnd={props.animateSpeed ? handleAnimationEnd : undefined}
      onClick={handleClick}
    >
      {props.word}
    </WordContainer>
  )
}

function generateSortedRandomDelays(length: number) {
  const minDelay = 10
  const maxDelayIntervalFrom = 500
  const maxDelayIntervalTo = 2000
  const normalized = Math.min(1, Math.max(0, length / 100))
  const N = Math.round(
    maxDelayIntervalFrom +
      (maxDelayIntervalTo - maxDelayIntervalFrom) * normalized,
  )
  const randomDelays: number[] = []

  for (let i = 0; i < length; i++) {
    const randomNumber =
      Math.floor(Math.random() * (N - minDelay + 1)) + minDelay
    randomDelays.push(randomNumber)
  }

  randomDelays.sort((a, b) => a - b)

  return randomDelays
}

export const ClickableWords = React.memo((props: ClickableWordsProps) => {
  const words = splitToWordsAndSymbols(props.children)
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)
  const delaysRef = useRef(generateSortedRandomDelays(words.length))

  if (props.disabled) {
    return props.children
  }

  return (
    <div
      className={cn(
        'size-fit select-none whitespace-pre-wrap',
        props.className,
      )}
    >
      {words.map((x, i) => {
        return (
          <Word
            key={i}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            animateSpeed={props.animateSpeed}
            onClick={props.onClick}
            word={x}
            delay={(props.delay ?? 0) + delaysRef.current[i]}
            index={i}
            onLastDisplayEnd={
              i == words.length - 1 ? props.onLastDisplayEnd : undefined
            }
            selectedWords={props.selectedWords}
          />
        )
      })}
    </div>
  )
})
ClickableWords.displayName = 'ClickableWords'

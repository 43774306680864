import { proxy } from 'valtio'
import { getLast, shuffleArray } from '../../../shared/lib/utils.ts'
import { AnswerStatus, AnswerStatusStore } from '../answerStatusDrawer.tsx'

export interface ListeningQuestion {
  soundUrl: string
  text: string
  options: readonly string[]
}

interface State {
  questions: readonly ListeningQuestion[]
  wordsForAnswers: readonly string[][]
  words: string[]
  answers: readonly string[]
  userAnswers: { index: number; isCorrect: boolean }[]
  currentAnswer?: { index: number; isCorrect: boolean }
  currentIndex: number
  showAnswer: boolean
  correctAnswersCount: number
}

export class ListeningExerciseStore {
  state: State

  getResult() {
    return this.state.userAnswers
      .map((word, i) => `${i + 1}. ${this.state.words[word.index]}`)
      .join('\n')
  }
  constructor(
    questions: readonly ListeningQuestion[],
    private answerStore: AnswerStatusStore,
    private onComplete: (result: string, correctAnswersCount: number) => void,
  ) {
    const answers = questions.map((text) => text.options[0])
    const options = questions.map((text) => text.options)
    this.state = proxy({
      questions,
      showAnswer: false,
      userAnswers: [],
      answers,
      wordsForAnswers: options.map(shuffleArray),
      get words() {
        return this.wordsForAnswers[this.currentIndex]
      },
      currentIndex: 0,
      correctAnswersCount: 0,
      get currentAnswer() {
        if (this.showAnswer) {
          return getLast(this.userAnswers)
        }
        return undefined
      },
    } as State)
  }

  nextQuestion() {
    if (this.state.currentIndex < this.state.questions.length - 1) {
      this.state.currentIndex += 1
      this.state.showAnswer = false
    } else {
      this.onComplete(
        'I have completed the exercise',
        this.state.correctAnswersCount,
      )
    }
  }

  select(index: number) {
    if (this.state.showAnswer) {
      return
    }
    const answer = this.state.answers[this.state.currentIndex]
    const userAnswer = this.state.words[index]
    const isCorrect = answer === userAnswer
    isCorrect && this.state.correctAnswersCount++
    this.state.userAnswers.push({ index, isCorrect })
    this.state.showAnswer = true
    this.answerStore.setStatus(
      isCorrect
        ? AnswerStatus.correct()
        : AnswerStatus.wrong({ description: 'Answer: ' + answer }),
      () => {
        this.nextQuestion()
      },
    )
  }
}

import React from 'react'
import { useSnapshot } from 'valtio'
import { useProfileStore } from './ProfileStore.ts'
import { TextInput } from '../../shared/ui/textInput/textInput.tsx'
import { Button } from '../../shared/ui/button/button.tsx'
import {
  ProfileButton,
  ProfileDrawer,
  ProfileMenuBlock,
  ProfileTitle,
} from './ProfileComponents.tsx'

export const ProfileManageAccount = () => {
  const store = useProfileStore()
  const state = useSnapshot(store.state)

  return (
    <div>
      <ProfileTitle title={'Manage account'} />
      <div className="flex flex-col gap-16">
        <ProfileMenuBlock>
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('name')
            }}
            title={state.name}
            subtitle="Name"
          />
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('email')
            }}
            title={state.email}
            subtitle="Email"
          />
          <ProfileButton
            withArrow
            onClick={() => {
              store.openAccountDrawer('password')
            }}
            title="********"
            subtitle="Password"
          />
        </ProfileMenuBlock>
        <ProfileMenuBlock>
          <ProfileButton
            alertTitle
            onClick={() => {
              store.setShowDeleteAccount(true)
            }}
            title="Delete account"
            subtitle="This is permanent"
          />
        </ProfileMenuBlock>
        <div className="px-16 text-16 text-light">
          Note: Deleting your account does not cancel your Edman Membership.
          Please, go to Manage Subscription for more.
        </div>
      </div>
      <EditDrawer />
      <DeleteAccountDrawer />
    </div>
  )
}

function EditDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  const title = `Change ${state.editField}`
  return (
    <ProfileDrawer open={!!state.editField} title={title}>
      <div className="flex flex-col">
        <TextInput
          size="large"
          type={state.editField === 'password' ? 'password' : 'text'}
          value={state.editValue}
          onChange={(e) => {
            store.setEditValue(e.target.value)
          }}
          className="mb-24"
        />
        <Button
          rounded="full"
          size="large"
          bg="blue-gradient"
          onClick={() => {
            void store.saveAccountChanges()
          }}
          className="mb-16"
        >
          Save
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.closeDrawer()
          }}
        >
          Cancel
        </Button>
      </div>
    </ProfileDrawer>
  )
}

function DeleteAccountDrawer() {
  const store = useProfileStore()
  const state = useSnapshot(store.state)
  return (
    <ProfileDrawer title="Delete account?" open={state.showDeleteAccount}>
      <div className="flex flex-col gap-16">
        <div>
          This action is permanent and cannot be reversed. Are you sure you want
          to proceed and delete your account?
          <br />
          <br />
          Please note that deleting your account will NOT cancel your Edman
          membership. To manage your membership, go back to the Settings screen
          and tap `Manage your subscription`.
        </div>

        <Button
          rounded="full"
          size="large"
          bg="red-gradient"
          onClick={() => {
            store.deleteAccount()
          }}
        >
          Delete account
        </Button>

        <Button
          bg="transparent"
          size="large"
          onClick={() => {
            store.setShowDeleteAccount(false)
          }}
        >
          Cancel
        </Button>
      </div>
    </ProfileDrawer>
  )
}

import { AppLocalStorage } from '../shared/lib/appLocalStorage.ts'
import { AppSessionStorage } from '../shared/lib/appSessionStorage.ts'
import { ToastStore } from '../features/toasts/toastStore.ts'
import { HttpClient } from '../shared/api/httpClient.ts'
import { AppAuthApi } from '../shared/api/authApi.ts'
import { AppChatApi } from '../shared/api/chatApi.ts'
import { AppProgressApi } from '../shared/api/progressApi.ts'
import { MainModule } from '../features/mainModule.ts'
import { ProgressStore } from '../features/progress/progressStore.ts'

const appStorage = new AppLocalStorage(localStorage)
const appSessionStorage = new AppSessionStorage(sessionStorage)
const hostname = import.meta.env.VITE_API_HOST
const toastStore = new ToastStore()
const httpClient = new HttpClient(hostname, toastStore)
const authApi = new AppAuthApi(httpClient)
const chatApi = new AppChatApi(httpClient)
const progressApi = new AppProgressApi(httpClient)
const progressStore = new ProgressStore(progressApi)

export const appMainModule = new MainModule(
  httpClient,
  authApi,
  toastStore,
  chatApi,
  progressApi,
  appStorage,
  appSessionStorage,
  progressStore,
)
